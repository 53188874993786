import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import { styled } from '@mui/material/styles';
import ImageCard from './imageCard';
import Checkbox from '@mui/material/Checkbox';
import Swal from 'sweetalert2'
import { MuiFileInput } from 'mui-file-input'
import axios from 'axios';
import { SaveNewsTopic ,UpdateNewsTopic,SaveImageTopic,LoadImageNewsTopic,DeleteNewsTopicImg} from "../../context";
import RichTextEditor from 'react-rte';
import { Backdrop, CircularProgress } from '@mui/material';
export default function AddModal({id,dataSelect,open,colors,onClose,reloadCar}) {
    const [news, setNewsDetail] = React.useState({
      news_topic1: '',
      news_topic2: '',
      news_image: ''
    });
    const [file, setFile] = React.useState();
    const [image, setImage] = React.useState(null);
    const [loading,setLoad] = React.useState(false);
    const [editorState, setEditorState] = React.useState(
      () => RichTextEditor.createEmptyValue(),
    );
    const handleChangeImage = (newValue) => {
      setFile(newValue) 
      var filename = Date.now().toString() +'.jpg';     
      setNewsDetail({...news,news_image:filename})
    }
    const handleTextFieldChange = (event) => {
      setNewsDetail({
        ...news,
        [event.target.name]: event.target.value,
      });
    };
    const { news_topic1, news_topic2} = news;
    const handleClose = () => {

      setNewsDetail({
        news_topic1: '',
        news_topic2: '',
        news_image: ''})
        setEditorState( () => RichTextEditor.createEmptyValue())       
        setFile()    
        setImage(null)   
        onClose()
      };


    React.useEffect(()=>{

      if(id){
         setNewsDetail({
            news_topic1: dataSelect.row.news_topic1,
            news_topic2: dataSelect.row.news_topic2,
            news_image:dataSelect.row.news_image
          })
         
          setEditorState(RichTextEditor.createValueFromString( dataSelect.row.news_detail, 'html'))
       
          setImage(dataSelect.row.news_image)
          
      }
      else{
        
      }
      
    },[open])
    async function saveData(){
      setLoad(true)
      var formData = new FormData();   
        formData.append("id",id);       
        formData.append("news_detail",editorState.toString('html'))
        Object.keys(news).map((name)=>{     
      
            formData.append(name,news[name])      
          }      
        )     
        await axios.post(id ? UpdateNewsTopic : SaveNewsTopic,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }).then(async res=>{     
           
             uploadToServer(news['news_image'])
             setLoad(false)
          
        }).catch(error=>{
          console.log(error);
        });      
        
      }
    const handleSave = async () => {
      if(file == null){
        if(dataSelect == null){
          Swal.fire({
            title: 'ข้อมูลไม่ครบ ไม่สามารถบันทึก',           
            icon: 'error',
            showCancelButton: true,       
            confirmButtonText: 'ตกลง',
            
         })
        }
        else{
          saveData()
        }

        
      }
      else{
        saveData()
   
     }
    }
    const onChange = (value) => {
      setEditorState(value)
      // setNewsDetail({
      //   ...news,news_detail: value,
      // });
     
    };
     async function uploadToServer(filename) {
      
      if(file != null){
       
           
          const formData = new FormData();
          formData.append("file", file);
          formData.append("fileName", filename);
          await axios.post(SaveImageTopic, formData, {
          headers: { "Content-Type": "multipart/form-data" },
          }).then(res => {})
      
    }
    
    handleClose()
   }
   

  const handleDeleteImage = (imageName)=>{
      
   
      var formData = new FormData();       
        formData.append("path",imageName);      
        axios.post(DeleteNewsTopicImg,
        formData,{
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }).then(res=>{          
           
        }).catch(error=>{
        
        })
            
      //   axios.post(DeleteImageCar,
      //   formData,{
      //     headers: {
      //       'Content-Type': 'multipart/form-data'
      //     }
      //   }).then(res=>{          
      
      //   }).catch(error=>{
      //     console.log(error)
      //   })
      setImage(null)
      setFile()

        reloadCar()
      
  }

  const removeImageData = ()=>{
      setImage(null)
      setFile()
  }
  return (
    <>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
       // onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{id ? 'อัพเดทข้อมูลพาดหัวข่าว':'เพิ่มข้อมูลพาดหัวข่าว'}</DialogTitle>
        <DialogContent>
        <Stack spacing={{ xs: 1, sm: 2 }}  direction="row" useFlexGap flexWrap="wrap" sx={{paddingTop:'10px',width:'480px'}}>
          
            <TextField
           
            autoFocus
            id="news_topic1"
            name ="news_topic1"
            value={news_topic1}
            onChange={handleTextFieldChange}
            label="หัวข้อ1"
            type="text"
            fullWidth
            variant="standard"
           // error
          />
           <TextField
            style={{marginTop:'10px',marginLeft:0}}
            autoFocus
            id="news_topic2"
            label="หัวข้อ2"
            name ="news_topic2"
            value={news_topic2}
            onChange={handleTextFieldChange}
            type="text"
            fullWidth
            variant="standard"
     
          />
          <Typography  style={{marginLeft:'0px',marginTop:'25px',display:'inline-flex'}} >รายละเอียด</Typography>
          <Box paddingTop={2} width={'100%'}>
            <RichTextEditor
           
              // toolbarConfig={toolbarConfig} 
              editorClassName="rte-editor"
              toolbarClassName="rte-toolbar"
              value={editorState}
              onChange={onChange}
            />
</Box>
            
            <Typography   style={{marginLeft:'0px',marginTop:'10px',display:'inline-flex'}} >รูปประกอบข่าว<Typography marginLeft={2} color={'red'}> แนะนำขนาด 1080x600</Typography> </Typography>
            
            <Button   style={{marginTop:'10px',marginBottom:'10px'}} component="label" variant="contained" startIcon={<CloudUploadIcon />}>
                   อัพโหลดรูปภาพ
                   <MuiFileInput   inputProps={{ accept: '.png, .jpeg, .jpg' }} size='small' style={{width:250, marginLeft:10}} color={'info'} value={file} onChange={handleChangeImage} />
            </Button>
           <Box sx={{width:'480px'}}>

            
           <Stack  sx={{width:'420px'}}  direction="row" useFlexGap flexWrap="wrap">
             
              { file  ? 
                <Box>
                <Box textAlign={'-webkit-center'} onClick={()=> removeImageData()} sx={{cursor:'pointer'}} backgroundColor={'Brown'} borderRadius={20} m={1}>
                {file.name}
                <IconButton aria-label="delete" size="small">
                  <DeleteIcon fontSize="small" />
                  </IconButton>
                  </Box>
              
                  <ImageCard imagePath={URL.createObjectURL(file)} imageLarge={URL.createObjectURL(file)}/>
                </Box>
            

                :''}
                    
                {image?
                
               
                <Box>
                <Box textAlign={'-webkit-center'} onClick={()=> handleDeleteImage(image)}  sx={{cursor:'pointer'}}  backgroundColor={'Brown'} borderRadius={20} m={1}>{image}
                <IconButton aria-label="delete" size="small" >
                  <DeleteIcon fontSize="small" />
                  </IconButton>
                  </Box>
                <ImageCard imagePath={LoadImageNewsTopic+image} imageLarge={LoadImageNewsTopic+image}/>
                </Box>
                :''}

            </Stack>
           </Box> 


        </Stack>

       
        

       
        </DialogContent>
        <DialogActions>
          <Button sx={{
              backgroundColor: colors.blueAccent[700],
              color: colors.grey[100],
           }} 
            onClick={handleClose}>ยกเลิก
            </Button>
          <Button  sx={{
              backgroundColor: colors.blueAccent[700],
              color: colors.grey[100],
           }} 
           onClick={handleSave}>บันทึก
           </Button>
        </DialogActions>
      </Dialog>
      </>
  );
}