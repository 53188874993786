import { Box, Typography, useTheme,Button, Grid } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import { mockDataCar } from "../../data/mockData";
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import AddModal from "./addModal";
import AddModalSub from "./addModalSub";
import Header from "../../components/Header";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import  React,{useEffect, useState} from 'react';
import axios from 'axios';
import { SelectNewsTopic,DeleteCarImg,LoadImageNewsTopic,SelectNewsSub,LoadImageNewsSub} from "../../context";
import { IconButton } from '@mui/material';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Swal from 'sweetalert2'
import EditIcon from '@mui/icons-material/Edit';
import { useNavigate } from "react-router-dom";
const NewsManage = () => {
  const navigate = useNavigate()
  useEffect(()=>{    
    if(localStorage.getItem('user')){
      if(JSON.parse(localStorage.getItem('user')).role == 3) window.location.assign('/cardetail')
        loadCar()
        loadSubNews()
    }
    else{
      navigate('/authen')
    }
  },[])

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [carId, setCarId] = useState(null);
  const [dataSelect, setDataSelect] = useState(null);
  const handleEdit = (value)=>{
    setDataSelect(value)
    setCarId(parseInt(value.id))
    setOpen(true)
    
   }
   const handleSubEdit = (value)=>{
    setDataSelect(value)
    setCarId(parseInt(value.id))
    setModelOpen(true)
    
   }
   const handleDelete = (value) => {    
    Swal.fire({
      title: 'คุณต้องการลบ '+value.row.brand_name +' หรือไม่?',           
      icon: 'warning',
      showCancelButton: true,       
      confirmButtonText: 'ตกลง',
      cancelButtonText: "ยกเลิก"
   }).then((result) => {
    if (result.isConfirmed) {
      var formData = new FormData();       
      formData.append("brandLogo", value.row.brand_logo);      
      axios.post(DeleteCarImg,
      formData,{
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(res=>{          
       // deleteItem(value)
      }).catch(error=>{
     
      });
    } else if (result.isDenied) {
      
    }
  })
}
  const columns = [
   
    {
      field: "news_topic1",
      headerName: "หัวข่าว1",    
      cellClassName: "name-column--cell",
      width: 200
    },
    {
      field: "news_topic2",
      headerName: "หัวข่าว2",    
      cellClassName: "name-column--cell",
      width: 200
    },
    {
      field: "newsImage",
      headerName: "ภาพหัวข่าว",
      width: 100,
      renderCell:  (params) => {
        try{
     
    
        return  (
          <>
             {params.row.news_image != null ?
                         
                  <img width={100} src={LoadImageNewsTopic+params.row.news_image} /> 
              
             :''}
          </> 
          )
         }catch(e){

          }
      }
      
    
    },
   
    
    {
    
      headerName: "",    
      cellClassName: "name-column--cell",
      width:100,
      renderCell: (params) => 
       <>
          
            <IconButton onClick={()=>handleEdit(params)}>
            <EditIcon />
          </IconButton>
          {/* <IconButton onClick={()=>handleDelete(params)}>
              <DeleteForeverIcon />
             </IconButton> */}
       </> 
    
  },          
   
  ];
  const columnsSub = [
   
    {
      field: "newssub_topic",
      headerName: "หัวข่าว",    
      cellClassName: "name-column--cell",
      width: 200
    },
    {
      field: "newssub_detail",
      headerName: "รายละเอียด",    
      cellClassName: "name-column--cell",
      width: 200
    },
    {
      field: "newssub_image",
      headerName: "ภาพหัวข่าว",
      width: 100,
      renderCell:  (params) => {
        try{
     
    
        return  (
          <>
             {params.row.newssub_image != null ?
                         
                  <img width={100} src={LoadImageNewsSub+params.row.newssub_image} /> 
              
             :''}
          </> 
          )
         }catch(e){

          }
      }
      
    
    },
   
    
    {
    
      headerName: "",    
      cellClassName: "name-column--cell",
      width:100,
      renderCell: (params) => 
       <>
          
            <IconButton onClick={()=>handleSubEdit(params)}>
            <EditIcon />
          </IconButton>
          {/* <IconButton onClick={()=>handleDelete(params)}>
              <DeleteForeverIcon />
             </IconButton> */}
       </> 
    
  },          
   
  ];
  const [open, setOpen] = useState(false);
  const [modelOpen, setModelOpen] = useState(false);
  const [colorOpen, setColorOpen] = useState(false);
  const [newsList,setNewsList] = useState()
  const [newsSub,setNewsSub] = useState()
  const addClick = ()=>{
    setOpen(true)
  }
  const addNewsSubClick = ()=>{
    setModelOpen(true)
  }

  const loadCar = () =>{
    
    axios.get(SelectNewsTopic)
    .then(res=>{
      
      setNewsList(res.data);

    }).catch(error=>{
      console.log(error);
    })
    }

    const loadSubNews = () =>{
    
      axios.get(SelectNewsSub)
      .then(res=>{
        
        setNewsSub(res.data);
  
      }).catch(error=>{
        console.log(error);
      })
      }
       
     
  return (<>
    <Box m="20px">
    <Grid container gap={2}>
        <Grid item xs={6}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
      <Header title="ข้อมูลพาดหัวข่าว" subtitle="จัดการข่าวสารได้จากที่นี้" />
      <Box > 
      <Button
            onClick={()=>addClick()}
            sx={{
              backgroundColor: colors.blueAccent[700],
              color: colors.grey[100],
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
              marginLeft:"10px"
            }}
          >   <AddCircleIcon sx={{ mr: "10px" }} />
            เพิ่มพาดหัวข่าว
        </Button>

     
        </Box>

      </Box>
      
      <Box
        m="0 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-cellContent": {
            fontSize:'16px'
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[800],
            borderBottom: "none",
          },
          // "& .MuiDataGrid-columnHeaderTitle": {
          //     fontSize:'15px'
          // },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[800],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
      {newsList ? <DataGrid  rows={newsList} columns={columns} components={{ Toolbar: GridToolbar }}/>:''}
        
      </Box>
        </Grid>
        <Grid item xs={5.5}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
      <Header title="ข้อมูลย่อยข่าวสาร" subtitle="จัดการข่าวสารได้จากที่นี้" />
   
      <Box > 
      <Button
            onClick={()=>addNewsSubClick()}
            sx={{
              backgroundColor: colors.blueAccent[700],
              color: colors.grey[100],
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
              marginLeft:"10px"
            }}
          >   <AddCircleIcon sx={{ mr: "10px" }} />
            เพิ่มพาดหัวข่าว
        </Button>

     
        </Box>

      </Box>
      
      <Box
        m="0 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-cellContent": {
            fontSize:'16px'
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[800],
            borderBottom: "none",
          },
          // "& .MuiDataGrid-columnHeaderTitle": {
          //     fontSize:'15px'
          // },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[800],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
      {newsSub ? <DataGrid  rows={newsSub} columns={columnsSub} components={{ Toolbar: GridToolbar }}/>:''}
        
      </Box>
          </Grid>

      </Grid>
  
    </Box>
    <AddModalSub open = {modelOpen} colors = {colors} id = {carId}  reloadCar={()=>loadSubNews()} dataSelect={dataSelect} onClose= {()=> {setModelOpen(false);setCarId(null);loadSubNews();setDataSelect(null)}}/>
   <AddModal open = {open} colors = {colors} id = {carId}  reloadCar={()=>loadCar()} dataSelect={dataSelect} onClose= {()=> {setOpen(false);setCarId(null);loadCar();setDataSelect(null)}}/>
   </>
  );
};

export default NewsManage;
