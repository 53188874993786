import { useState } from "react";
import { Routes, Route } from "react-router-dom";
import Topbar from "./scenes/global/Topbar";
import Sidebar from "./scenes/global/Sidebar";
import Dashboard from "./scenes/dashboard";
import Team from "./scenes/team";
import CarManage from "./scenes/cardetail";
import Invoices from "./scenes/invoices";
import Contacts from "./scenes/contacts";
import Bar from "./scenes/bar";
import Form from "./scenes/form";
import Line from "./scenes/line";
import Pie from "./scenes/pie";
import FAQ from "./scenes/faq";
import NewsManage from "./scenes/news";
import Geography from "./scenes/geography";
import CarDetail from "./scenes/cardetailView";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";
import Calendar from "./scenes/calendar/calendar";
import Authen from "./components/authen";


function App() {
  const [theme, colorMode] = useMode();
  const [isSidebar, setIsSidebar] = useState(true);

  return (
   
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="app">
      {localStorage.getItem('user') &&  <Sidebar isSidebar={isSidebar} />}   
          <main className="content">
        {localStorage.getItem('user') && <Topbar setIsSidebar={setIsSidebar} />} 
            <Routes>
              <Route path="/" element={<Dashboard />} />
              <Route path="/news" element={<NewsManage/>}/>
              <Route path="/team" element={<Team />} />
              <Route path="/contacts" element={<Contacts />} />
              <Route path="/invoices" element={<Invoices />} />
              <Route path="/form" element={<Form />} />
              <Route path="/bar" element={<Bar />} />
              <Route path="/pie" element={<Pie />} />
              <Route path="/line" element={<Line />} />
              <Route path="/faq" element={<FAQ />} />
              <Route path="/authen" element={<Authen />} />
              <Route path="/geography" element={<Geography />} />
              <Route path="/carDetailView" element={<CarDetail />} />
              <Route path="/cardetail" element={<CarManage />} />
            </Routes>
          </main>
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
   
  );
}

export default App;
