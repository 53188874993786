import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import './authenStyle.css'
import { user } from '../data/authen'
import Swal from 'sweetalert2'
const Authen = (props) => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [emailError, setEmailError] = useState('')
  const [passwordError, setPasswordError] = useState('')

  const navigate = useNavigate()

  const onButtonClick = async () => {
    let obj = user.find(o => o.user === email && o.password === password);
    if(obj) {
        localStorage.setItem('user',JSON.stringify(obj))
        if(JSON.parse(localStorage.getItem('user')).role != 3) window.location.assign('/')
        else { window.location.assign('/cardetail')}
    
    }
    else{
        Swal.fire({
            title: 'ข้อมูลไม่ถูกต้อง',           
            icon: 'error',
            showCancelButton: false,       
            confirmButtonText: 'ตกลง',
            
         })
    }
  }

  return (
    <div className={'mainContainer'}>
      <div className={'titleContainer'}>
        <div>BIGAPPLECAR MANAGE </div>
      </div>
      <br />
      <div className={'inputContainer'}>
        <input
          value={email}
          placeholder="Enter your username here"
          onChange={(ev) => setEmail(ev.target.value)}
          className={'inputBox'}
        />
        <label className="errorLabel">{emailError}</label>
      </div>
      <br />
      <div className={'inputContainer'}>
        <input
         type='password'
          value={password}
          placeholder="Enter your password here"
          onChange={(ev) => setPassword(ev.target.value)}
          className={'inputBox'}
        />
        <label className="errorLabel">{passwordError}</label>
      </div>
      <br />
      <div className={'inputContainer'}>
        <input className={'inputButton'} type="button" onClick={onButtonClick} value={'Log in'} />
      </div>
    </div>
  )
}

export default Authen