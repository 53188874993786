import { Box, IconButton } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import { mockDataContacts } from "../../data/mockData";
import Header from "../../components/Header";
import { useTheme } from "@mui/material";
import axios from 'axios';
import { SelectMailBox, UpdateArchive } from "../../context";
import { useState } from "react";
import { useEffect } from "react";
import ArchiveIcon from '@mui/icons-material/Archive';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import Swal from "sweetalert2";
import MarkEmailUnreadIcon from '@mui/icons-material/MarkEmailUnread';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import ShowCar from "./modal";
import { useNavigate } from "react-router-dom";

const Contacts = () => {
  
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [open,setOpen] = useState(false)
  const [data,setData] = useState()
  const [mailList,setMailList] = useState()
  const navigate = useNavigate()
  const loadMailBox = ()=>{
    axios.get(SelectMailBox)
    .then(res=>{      
      console.log(res.data)
      setMailList(res.data);

    }).catch(error=>{
      console.log(error);
    })
  }
  const columns = [
       
    { 
      field:'mailbox_archive',
      headerName: "",       
      width:40,
      renderCell: (params) => 
       <>
          {params.row.mailbox_archive == 1 ? <MarkEmailUnreadIcon style={{color:'orange'}}/>:<MarkEmailReadIcon style={{color:'gray'}}/>}
          
       </> 
    
  },          
    {
      field: "mailbox_name",
      headerName: "ชื่อผู้ติดต่อ",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "mailbox_line_id",
      headerName: "Line ID",
      headerAlign: "left",
      align: "left",
    },
    {
      field: "mailbox_phone",
      headerName: "เบอร์โทรศัพท์",
      flex: 1,
    },
    {
      field: "brand_name",
      headerName: "ยี่ห้อรถ ที่เกี่ยวข้อง",
      flex: 1,
    },
    {
      field: "car_model",
      headerName: "รุ่นรถ ที่เกี่ยวข้อง",
      flex: 1,
    },
    {
      field: "mailbox_message",
      headerName: "ข้อความ",
      flex: 1,
    },
    {    
      headerName: "",    
      cellClassName: "name-column--cell",
      width:80,
      renderCell: (params) => 
       <>
          <IconButton onClick={()=>handleDetail(params)}>
            <OpenInFullIcon />
          </IconButton>
            <IconButton onClick={()=>handleArchiveEdit(params)}>
            <ArchiveIcon />
          </IconButton>
          
       </> 
    
  },          
   
   
  ];
  useEffect(()=>{
    if(localStorage.getItem('user')){
      if(JSON.parse(localStorage.getItem('user')).role == 3) window.location.assign('/cardetail')
     loadMailBox()
  }
  else{
     navigate('/authen')
  }
   
  },[])
  const handleDetail= (params)=>{
     setOpen(true)
     setData(params.row)
  }

  const handleArchiveEdit= async (params)=>{
    console.log(params.row )
    var formData = new FormData();   
    formData.append("id",params.row.mailId)
    formData.append("mailbox_archive",params.row.mailbox_archive == 1 ? 0 : 1);

    await axios.post(UpdateArchive,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }).then( res=>{     
      
      Swal.fire({
        title: 'Mail Archive',           
        icon: 'success',
        timer: 3000,
        showCancelButton: false,       
        confirmButtonText: 'Ok',
        
     })
       loadMailBox()
    }).catch(error=>{
      console.log(error);
    });      
  }
  return (
    <>
      <ShowCar open={open} colors={colors}  data = {data} onClose={()=> setOpen(false)} />
    <Box m="20px">
      <Header
        title="ผู้ติดต่อ"
        subtitle="List of Contacts for Future Reference"
      />
      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[800],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[800],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
      {mailList ? <DataGrid
          rows={mailList}
          columns={columns}
          components={{ Toolbar: GridToolbar }}
        />
      :''} 
      </Box>
    </Box>
    </>
  );
};

export default Contacts;
