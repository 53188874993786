import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Box, Divider, Grid, Typography } from '@mui/material';
import { LoadImageCar } from '../../context';
import { useCookies } from 'react-cookie';
import CarDetail from '../cardetailView';
export default function ShowCar({open,colors,data,onClose}) {
    const [color, setColor] = React.useState('');
    const [modalOpen,setModalOpen] = React.useState(false);
    const handleClose = () => {
     setColor('')
     onClose()
     };
    

     
     const divStyle = {
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'flex-start',
        backgroundSize: 'contain',
        backgroundRepeat:'no-repeat',
        objectFit:'center',
        height: '190px',        
       
        backgroundPositionX:'center'
      }
    
  return (
    <>
      <CarDetail car_id={data ? data.car_id:0} modalOpen={modalOpen} modalClose={()=> setModalOpen(false)}/>
    {data?
    <>
   
      <Dialog open={open} onClose={handleClose} 
      fullWidth
      maxWidth="sm">
        <DialogTitle>Mail</DialogTitle>
        <DialogContent >
            <Grid container xs={12}>
            <Grid item xs={5}>
            <Typography  display={'contents'}>ชื่อผู้ติดต่อ: <Typography  display={'contents'}>{data.mailbox_name}</Typography></Typography>
            <Typography  >Line ID: <Typography fontWeight={'bold'} display={'contents'}>{data.mailbox_line_id}</Typography></Typography>
            <Typography  >เบอร์โทร: <Typography fontWeight={'bold'} display={'contents'}>{data.mailbox_phone}</Typography></Typography>
            <Typography marginBottom={1} marginTop={1} >ข้อความ: <Typography display={'contents'} style={{wordWrap:'break-word'}}>{data.mailbox_message}</Typography></Typography>
            <Typography  >วันที่: <Typography display={'contents'}>{data.mailbox_create}</Typography></Typography>
            </Grid>
            <Grid item xs={7}>
            <Box  style={{ ...divStyle, 'backgroundImage': `url(${LoadImageCar+'Thumbnails_'+data.image_pathEx})` }}>               
                  </Box> 
              <Typography  variant='h3' display={'contents'}> {data.brand_name} {data.car_model} </Typography>
              <Divider style={{margin:10}}></Divider>
              <Button sx={{
                backgroundColor: colors.greenAccent[600],
                color: colors.grey[100],
            }} 
                onClick={()=>{
                   
                setModalOpen(true)
                }}>
                    รายละเอียด
            </Button>
            </Grid>
            </Grid>
       
        </DialogContent>
        <DialogActions>
          <Button sx={{
              backgroundColor: colors.blueAccent[700],
              color: colors.grey[100],
           }} 
            onClick={handleClose}>ออก
            </Button>
         
        </DialogActions>
      </Dialog>
      
       </>
      :''}
     
    </>
  );
}