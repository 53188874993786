import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import { styled } from '@mui/material/styles';
import ImageCard from './imageCard';
import Checkbox from '@mui/material/Checkbox';
import Swal from 'sweetalert2'
import { MuiFileInput } from 'mui-file-input'
import axios from 'axios';
import { SaveVideoPath,SaveCarVideo,SelectBrandName,SaveCar,UpdateCar,SelectColor,LoadVideoCar,LoadImageCar,SaveCarImg,DeleteCarVideo, DeleteCarImg,SaveImagePath,DeleteImageCar,DeleteVideoCar,SelectImageCar} from "../../context";
import RichTextEditor from 'react-rte';
import { Backdrop, CircularProgress } from '@mui/material';
export default function AddModal({id,dataSelect,open,colors,onClose,reloadCar}) {
    const [brand, setBrand] = React.useState(0);
    const [carDetail, setCarDetail] = React.useState({
      car_model: '',
      car_price: null,
      car_year: null,
      car_mile: null,
      car_fuel: '',
      car_cc: null,
      car_tranmission: '',
      car_detail: '',
    });
    const [editorState, setEditorState] = React.useState(
      () => RichTextEditor.createEmptyValue(),
    );
    const [color, setColor] = React.useState(0);
    const [fecthBrand, setFecthBrand] = React.useState(null);
    const [fecthColor, setFecthColor] = React.useState(null);
    const [fileEx, setFileEx] = React.useState([]);
    const [fileIn, setFileIn] = React.useState([]);
    const [fileVideo, setFileVideo] = React.useState();
    const [imgData, setImgData] = React.useState([]);
    const [imgDataIn, setImgDataIn] = React.useState([]);
    const [video, setVideo] = React.useState(null);
    const [imageEx, setImageEx] = React.useState(null);
    const [imageIn, setImageIn] = React.useState(null);
    const [loading,setLoad] = React.useState(false);
    const [check, setCheck] = React.useState({
      promotion: false,
      sell: false,
      hide: false,
    });
    const handleChangeEx = (newValue) => {
      setFileEx(newValue) 
      var arr = []
      newValue.map((value)=>{     
        arr.push(URL.createObjectURL(value))
    })
     setImgData(arr);
     
    }
    
    const handleChangeVideo = (newValue) => {
      setFileVideo(newValue) 
     
    }
    const handleChangeIn = (newValue) => {
      setFileIn(newValue) 
      var arr = []
      newValue.map((value)=>{     
        arr.push(URL.createObjectURL(value))
    })
      setImgDataIn(arr);
     
    }
    
    const handleCheckboxChange = (event) => {
      setCheck({
        ...check,
        [event.target.name]: event.target.checked,
      });
    };
    const handleTextFieldChange = (event) => {
      setCarDetail({
        ...carDetail,
        [event.target.name]: event.target.value,
      });
    };
    const { promotion, sell, hide } = check;
    const { car_model, car_price,car_year, car_mile, car_fuel, car_cc, car_tranmission, car_detail} = carDetail;
    const handleClose = () => {
      setCheck({promotion: false, sell: false,hide: false})

      setCarDetail({
        car_model: '',
        car_pice: null,
        car_mile: null,
        car_fuel: '',
        car_cc: null,
        car_tranmission: '',
        car_detail: '',})
        setEditorState( () => RichTextEditor.createEmptyValue())
        setColor(0)
        setBrand(0)
        setFileEx([]) 
        setFileIn([])
        setFileVideo()
        setImgData([])  
        setImgDataIn([])   
        setVideo(null)
        onClose()
      };
    const handleBrandChange = (event) => {
        setBrand(event.target.value);
    };
  ;
    const handleColorChange = (event) => {
        setColor(event.target.value);
    };
    
    const loadBrand = ()=>{
    axios.get(SelectBrandName)
    .then(res=>{

        setFecthBrand(res.data);

    }).catch(error=>{
        console.log(error);
    })
    }
    const loadColor = ()=>{
      axios.get(SelectColor)
      .then(res=>{
  
          setFecthColor(res.data);
  
      }).catch(error=>{
          console.log(error);
      })
    }
    React.useEffect(()=>{

      if(id){
          setCarDetail({
            car_model: dataSelect.row.car_model,
            car_price: parseInt(dataSelect.row.car_price),
            car_year: parseInt(dataSelect.row.car_year),
            car_mile: parseInt(dataSelect.row.car_mile),
            car_fuel: dataSelect.row.car_fuel,
            car_cc: parseInt(dataSelect.row.car_cc),
            car_tranmission: dataSelect.row.car_tranmission,
            car_detail: dataSelect.row.car_detail,
          })
          setColor(dataSelect.row.car_color)
          setBrand(dataSelect.row.car_brand)
          setEditorState(RichTextEditor.createValueFromString( dataSelect.row.car_detail, 'html'))
          setCheck({ 
            promotion: dataSelect.row.car_promotion == 0 ? true:false,
            sell: dataSelect.row.car_sell == 0 ? true:false,
            hide: dataSelect.row.car_hide == 0 ? true:false})
          setImageEx(dataSelect.row.image_pathEx)
          setImageIn(dataSelect.row.image_pathIn)
          setVideo(dataSelect.row.video_path)
          
      }
      else{
        
      }
      
    },[open])
    React.useEffect(()=>{
      loadBrand()
      loadColor()
    
     
    },[])
    async function saveData(){
      setLoad(true)
      var formData = new FormData();   
        formData.append("id",id);
        formData.append("car_brand",brand);
        formData.append("car_color",color);
        //formData.append("name",name);
        
        Object.keys(carDetail).map((name)=>
          {     
      
            formData.append(name,carDetail[name])      
          }      
        )     
  
        Object.keys(check).map((name)=>
          {         
            
            formData.append(name,check[name] ? 0 : 1)      
          }      
        )     
        formData.append("richText",editorState.toString('html'))    
 
        await axios.post(id ? UpdateCar : SaveCar,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }).then(async res=>{     
          
             await id ? uploadToServer( id ) : uploadToServer(res.data.data)   
            
          
        }).catch(error=>{
          console.log(error);
        });      
        
      }
    const handleSave = async () => {
      if(fileEx == null || fileIn == null ){
        if(dataSelect == null){
          Swal.fire({
            title: 'ข้อมูลไม่ครบ ไม่สามารถบันทึก',           
            icon: 'error',
            showCancelButton: true,       
            confirmButtonText: 'ตกลง',
            
         })
        }
        else{
          saveData()
        }

        
      }
      else{
        saveData()
   
     }
    }
     async function saveImagePath(name,lastid,type) {
     
        const formData = new FormData();
        formData.append("image_path", name);
        formData.append("car_type", type);
        formData.append("car_id", lastid);
        await axios.post(SaveImagePath, formData, {
         headers: { "Content-Type": "multipart/form-data" },
        }).then(res => {})

      }
      async function saveVideoPath(name,lastid,type) {
     
        const formData = new FormData();
        formData.append("video_path", name);   
        formData.append("car_id", lastid);
        await axios.post(SaveVideoPath, formData, {
         headers: { "Content-Type": "multipart/form-data" },
        }).then(res => {})

      }
      
     async function uploadToServer(lastid) {
      
      if(fileEx != null){
        fileEx.map(async (item)=>{
          var filename =  lastid + Date.now().toString() +'.jpg';
          saveImagePath(filename,lastid,0)
          const formData = new FormData();
          formData.append("file", item);
          formData.append("fileName", filename);
          await axios.post(SaveCarImg, formData, {
          headers: { "Content-Type": "multipart/form-data" },
          }).then(res => {})
        })
    }
    if(fileIn != null){
        fileIn.map(async (item)=>{
          var filename =  lastid + Date.now().toString() +'.jpg';
          saveImagePath(filename,lastid,1)        
          const formData = new FormData();
          formData.append("file", item);
          formData.append("fileName", filename);
          await axios.post(SaveCarImg, formData, {
          headers: { "Content-Type": "multipart/form-data" },
          }).then(res => {})
        })
    }

    if(fileVideo != null){
     
        var filename =  lastid + Date.now().toString() + '.'+fileVideo.name.split('.').pop();
        
        saveVideoPath(filename,lastid)        
        const formData = new FormData();
        formData.append("file", fileVideo);
        formData.append("fileName", filename);
        await axios.post(SaveCarVideo, formData, {
        headers: { "Content-Type": "multipart/form-data" },
        }).then(res => {})
      
  }
    handleClose()
    setLoad(false)
   }
   async function deleteimg() {
    try {
      if(fileEx != null && dataSelect){ 
        dataSelect.row.image_pathEx.split(",").map((img)=>{
  
          var formData = new FormData();       
          formData.append("path",img);      
          axios.post(DeleteCarImg,
          formData,{
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }).then(res=>{          
             
          }).catch(error=>{
          
          })
              
          axios.post(DeleteImageCar,
          formData,{
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }).then(res=>{          
            
          }).catch(error=>{
            console.log(error)
          })
        })
        
      }
      if(fileIn != null && dataSelect){ 
        dataSelect.row.image_pathIn.split(",").map((img)=>{
  
          var formData = new FormData();       
          formData.append("path",img);      
          axios.post(DeleteCarImg,
          formData,{
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }).then(res=>{          
             
          }).catch(error=>{
          
          })
        
          axios.post(DeleteImageCar,
          formData,{
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }).then(res=>{          
           
          }).catch(error=>{
            console.log(error)
          })
        })
       
      }
      
    } catch (error) {
      
    }
    

        uploadToServer(id) 
   
  }
  const onChange = (value) => {
    setEditorState(value)
    
   
  };
  const toolbarConfig = {
    // Optionally specify the groups to display (displayed in the order listed).
    display: ['INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS', 'LINK_BUTTONS', 'BLOCK_TYPE_DROPDOWN', 'HISTORY_BUTTONS'],
    INLINE_STYLE_BUTTONS: [
      {label: 'Bold', style: 'BOLD', className: 'custom-css-class'},
      {label: 'Italic', style: 'ITALIC'},
      {label: 'Underline', style: 'UNDERLINE'}
    ],
    BLOCK_TYPE_DROPDOWN: [
      {label: 'Normal', style: 'unstyled'},
      {label: 'Heading Large', style: 'header-one'},
      {label: 'Heading Medium', style: 'header-two'},
      {label: 'Heading Small', style: 'header-three'}
    ],
    BLOCK_TYPE_BUTTONS: [
      {label: 'UL', style: 'unordered-list-item'},
      {label: 'OL', style: 'ordered-list-item'}
    ]
  };
  const handleVideoDelete = async (video) =>{
    
    var formData = new FormData();       
    formData.append("path",video);      
   await axios.post(DeleteCarVideo,
    formData,{
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }).then(res=>{          
       
    }).catch(error=>{
      console.log(error)
    })
    
    axios.post(DeleteVideoCar,
      formData,{
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(res=>{          
    
      }).catch(error=>{
        console.log(error)
      })
      setVideo(null)
   
  }
  const handleDeleteImage = async (imageName)=>{
      
   
      var formData = new FormData();       
        formData.append("path",imageName);      
      await  axios.post(DeleteCarImg,
        formData,{
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }).then(res=>{          
           
        }).catch(error=>{
          console.log(error)
        })
            
        await axios.post(DeleteImageCar,
        formData,{
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }).then(res=>{          
      
        }).catch(error=>{
          console.log(error)
        })

  
        formData = new FormData();       
        formData.append("car_id", dataSelect.row.id);   
        formData.append("type", 0);    
        await  axios.post(SelectImageCar,
        formData,{
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }).then(res=>{     
          setImageEx(res.data[0].image_path)
        }).catch(error=>{
          console.log(error)
          setImageEx(null)
        })
        console.log(dataSelect.row.id)
        formData = new FormData();       
        formData.append("car_id", dataSelect.row.id);   
        formData.append("type", 1);    
        await  axios.post(SelectImageCar,
        formData,{
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }).then(res=>{        
          console.log(res)
          setImageIn(res.data[0].image_path)
        }).catch(error=>{
          console.log(error)
          setImageIn(null)
        })
            

        reloadCar()
      
  }

  const removeImageData= (img)=>{      

     const myNewFiles = [...fileEx]
     myNewFiles.map((item,index)=>{
        if(item.name == img.name)  myNewFiles.splice(index,1)

     })

      setFileEx(myNewFiles)
  }
  const removeImageDataIn= (img)=>{      
      
    const myNewFiles = [...fileIn]
    myNewFiles.map((item,index)=>{
       if(item.name == img.name)  myNewFiles.splice(index,1)

    })

     setFileIn(myNewFiles)
 }
 const removeVideoData = ()=>{      
      
  
   setFileVideo()
}
 
  return (
    <>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1000 }}
        open={loading}
       // onClick={handleClose}
      >
        <CircularProgress color="inherit" />
        <Typography variant='logo'>Wait Loading</Typography>
      </Backdrop>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{id ? 'อัพเดทข้อมูลรถ':'เพิ่มข้อมูลรถ'}</DialogTitle>
        <DialogContent>
        <Stack spacing={{ xs: 1, sm: 2 }}  direction="row" useFlexGap flexWrap="wrap" sx={{paddingTop:'10px',width:'480px'}}>
            <InputLabel id="car_brand" >ยี่ห้อ</InputLabel>
            <Select
                size='small'
                labelId="car_brand"
                id="car_brand"
                value={brand}
                
                onChange={handleBrandChange}
                sx={{width:'263px'}}
            >
              {fecthBrand ? fecthBrand.map((value,index) => (

                <MenuItem value={value.id}>{value.brand_name}</MenuItem>
              )):''}

           
               
            </Select>
            <InputLabel id="car_color" >สีรถ</InputLabel>
            <Select
             size='small'
                labelId="car_color"
                id="car_color"
                value={color}
           
                onChange={handleColorChange}
                sx={{width:'100px'}}
            >
                {fecthColor ? fecthColor.map((value,index) => (

                 <MenuItem value={value.id}>{value.color_name}</MenuItem>
            )):''}

            </Select> 
           
            <TextField
            style={{width:'250px',marginLeft:'0px',marginTop:'10px'}}
            autoFocus
            id="car_model"
            name ="car_model"
            value={car_model}
            onChange={handleTextFieldChange}
            label="รุ่นรถ"
            type="text"
            fullWidth
            variant="standard"
           // error
          />
           <TextField
             style={{width:'100px',marginLeft:'16px',marginTop:'10px'}}
            autoFocus
            id="car_price"
            label="ราคา"
            name ="car_price"
            value={car_price}
            onChange={handleTextFieldChange}
            type="number"
            fullWidth
            variant="standard"
           // error
          />
             <TextField
             style={{width:'70px',marginLeft:'16px',marginTop:'10px'}}
            autoFocus
            id="car_year"
            label="ปี"
            name ="car_year"
            value={car_year}
            onChange={handleTextFieldChange}
            type="number"
            fullWidth
            variant="standard"
           // error
          />
            <TextField
             style={{width:'80px',marginLeft:'0px',marginTop:'10px'}}
            autoFocus
            id="car_mile"
            name ="car_mile"
            value={car_mile}
            onChange={handleTextFieldChange}
            label="ไมล์"
            type="number"
            fullWidth
            variant="standard"
           // error
          />
          <TextField
             style={{width:'120px',marginLeft:'16px',marginTop:'10px'}}
            autoFocus
            id="car_fuel"
            label="เชื้อเพลิง"
            name ="car_fuel"
            value={car_fuel}
            onChange={handleTextFieldChange}
            type="text"
            fullWidth
            variant="standard"
           // error
          />
            <TextField
             style={{width:'100px',marginLeft:'16px',marginTop:'10px'}}
            autoFocus
            id="car_cc"
            label="ความจุเครื่องยนต์"
            name ="car_cc"
            value={car_cc}
            onChange={handleTextFieldChange}
            type="number"
            fullWidth
            variant="standard"
           // error
          />
             <TextField
             style={{width:'100px',marginLeft:'16px',marginTop:'10px',marginBottom:'20px'}}
            autoFocus
            id="car_transmission"
            name ="car_tranmission"
            value={car_tranmission}
            onChange={handleTextFieldChange}
            label="เกียร์"
            type="text"
            fullWidth
            variant="standard"
           // error
          />
   
          {/* <TextField
            id="car_detail"
            label="รายละเอียดรถ"
            name ="car_detail"
            value={car_detail}
            onChange={handleTextFieldChange}
            multiline
            rows={5}
            style={{width:'480px',marginLeft:'0px',marginTop:'30px'}}
            variant="outlined"
            
            />      */}
          
              <RichTextEditor
              toolbarConfig={toolbarConfig} 
              editorClassName="rte-editor"
              toolbarClassName="rte-toolbar"
              value={editorState}
              onChange={onChange}
            />
            <FormGroup style={{display:'inline',width:600,margin:0,paddingTop:10}}>
              <FormControlLabel control={<Checkbox color='secondary'  checked={promotion} onChange={handleCheckboxChange} name="promotion"/>} label="Hot Sale" />
              <FormControlLabel  control={<Checkbox color='secondary' checked={sell} onChange={handleCheckboxChange} name="sell"/>} label="ขายแล้ว" />
              <FormControlLabel  control={<Checkbox color='error' checked={hide} onChange={handleCheckboxChange} name="hide"/>} label="ปิดใช้งาน"  />
            </FormGroup>
           
            <Typography   style={{marginLeft:'0px',marginTop:'10px',display:'inline-flex'}} >รูปรถ (ภายนอก)  <Typography marginLeft={2} color={'red'}> แนะนำขนาด 1080x600</Typography> </Typography>
            
            <Button   style={{marginTop:'10px',marginBottom:'10px'}} component="label" variant="contained" startIcon={<CloudUploadIcon />}>
                   อัพโหลดรูปภาพ
                   <MuiFileInput  multiple inputProps={{ accept: '.png, .jpeg, .jpg' }}  size='small' style={{width:250, marginLeft:10}} color={'info'} value={fileEx} onChange={handleChangeEx} />
            </Button>
           <Box sx={{width:'480px'}}>

            
           <Stack  sx={{width:'420px'}}  direction="row" useFlexGap flexWrap="wrap">
              {!dataSelect  || imageEx == null  ? fileEx.map((img)=>(
                <ImageCard imagePath={URL.createObjectURL(img)} imageLarge ={URL.createObjectURL(img)}/>

              )):<> 
                  { fileEx.length > 0 ? fileEx.map((img)=>(
                 <Box>
                 <Box textAlign={'-webkit-center'} onClick={()=> removeImageData(img)} sx={{cursor:'pointer'}} backgroundColor={'Brown'} borderRadius={20} m={1}>
                  
                 <IconButton aria-label="delete" size="small">
                   <DeleteIcon fontSize="small" />
                   </IconButton>
                   </Box>
                 <ImageCard imagePath={URL.createObjectURL(img)} imageLarge={URL.createObjectURL(img)}/>
                 </Box>
             

                )):''}
                
                {imageEx.split(",").map((img)=>(
                
               
                <Box>
                <Box textAlign={'-webkit-center'} onClick={()=> handleDeleteImage(img)}  sx={{cursor:'pointer'}}  backgroundColor={'Brown'} borderRadius={20} m={1}>{img}
                <IconButton aria-label="delete" size="small" >
                  <DeleteIcon fontSize="small" />
                  </IconButton>
                  </Box>
                <ImageCard imagePath={LoadImageCar+'Thumbnails_'+img} imageLarge={LoadImageCar+img}/>
                </Box>
            
             

              ))}
              </>}
             
             
            </Stack>
           </Box> 

           <Typography   style={{marginLeft:'0px',marginTop:'10px',display:'inline-flex'}} >รูปรถ (ภายใน)  <Typography marginLeft={2} color={'red'}> แนะนำขนาด 1080x600</Typography> </Typography>
            
            <Button   style={{marginTop:'10px',marginBottom:'10px'}} component="label" variant="contained" startIcon={<CloudUploadIcon />}>
                   อัพโหลดรูปภาพ
                   <MuiFileInput  multiple inputProps={{ accept: '.png, .jpeg, .jpg' }}  size='small' style={{width:250, marginLeft:10}} color={'info'} value={fileIn} onChange={handleChangeIn} />
            </Button>
           <Box sx={{width:'480px'}}>
           <Stack  sx={{width:'420px'}}  direction="row" useFlexGap flexWrap="wrap">

           {!dataSelect  ||imageIn == null ? fileIn.map((img)=>(
                <ImageCard imagePath={URL.createObjectURL(img)} imageLarge ={URL.createObjectURL(img)}/>

              )):<> 
              { fileIn.length > 0 ? fileIn.map((img)=>(
             <Box>
             <Box textAlign={'-webkit-center'} onClick={()=> removeImageDataIn(img)} sx={{cursor:'pointer'}} backgroundColor={'Brown'} borderRadius={20} m={1}>
              
             <IconButton aria-label="delete" size="small">
               <DeleteIcon fontSize="small" />
               </IconButton>
               </Box>
               <ImageCard imagePath={URL.createObjectURL(img)} imageLarge={URL.createObjectURL(img)}/>
             </Box>
         

            )):''}
            
            {imageIn.split(",").map((img)=>(
            
           
            <Box>
            <Box textAlign={'-webkit-center'} onClick={()=> handleDeleteImage(img)}  sx={{cursor:'pointer'}}  backgroundColor={'Brown'} borderRadius={20} m={1}>
              {img}
            <IconButton aria-label="delete" size="small" >
              <DeleteIcon fontSize="small" />
              </IconButton>
              </Box>
            <ImageCard imagePath={LoadImageCar+'Thumbnails_'+img} imageLarge={LoadImageCar+img}/>
            </Box>
        
         

          ))}
          </>}
            
            
            </Stack>
           </Box> 



           <Typography   style={{marginLeft:'0px',marginTop:'10px',display:'inline-flex'}} >วิดีโอ แนะนำรถ <Typography marginLeft={2} color={'red'}> แนะนำขนาด 1920x1080</Typography> </Typography>
            
            <Button   disabled = {video ? true : false }style={{marginTop:'10px',marginBottom:'10px'}} component="label" variant="contained" startIcon={<CloudUploadIcon />}>
                   อัพโหลดวิดีโอ
                   <MuiFileInput inputProps={{ accept: 'video/*' }}  size='small' style={{width:250, marginLeft:10}} color={'info'} value={fileVideo} onChange={handleChangeVideo} />
            </Button>
           <Box sx={{width:'480px'}}>
           <Stack  justifyContent={'center'} sx={{width:'420px'}}  direction="row" useFlexGap flexWrap="wrap">

              { fileVideo  ? 
             <Box>
             <Box textAlign={'-webkit-center'} onClick={()=> removeVideoData()} sx={{cursor:'pointer'}} backgroundColor={'Brown'} borderRadius={20} m={1}>
              {fileVideo.name}
             <IconButton aria-label="delete" size="small">
               <DeleteIcon fontSize="small" />
               </IconButton>
               </Box>
               <video width="100%" controls >
                <source src={URL.createObjectURL(fileVideo)} type="video/mp4"/>
              </video>
             </Box>
         

            :''}
            
           
           {video?
             <Box>
             <Box textAlign={'-webkit-center'} onClick={()=> handleVideoDelete(video)}  sx={{cursor:'pointer'}}  backgroundColor={'Brown'} borderRadius={20} m={1}>{video}
             <IconButton aria-label="delete" size="small" >
               <DeleteIcon fontSize="small" />
               </IconButton>
               </Box>
             
             <video width="100%" controls >
                <source src={LoadVideoCar+video} type="video/mp4"/>
              </video>
             </Box>
           :''}
          
        

       
         
            
            
            </Stack>
           </Box> 
     
     


        </Stack>

       
        

       
        </DialogContent>
        <DialogActions>
          <Button sx={{
              backgroundColor: colors.blueAccent[700],
              color: colors.grey[100],
           }} 
            onClick={handleClose}>ยกเลิก
            </Button>
          <Button  sx={{
              backgroundColor: colors.blueAccent[700],
              color: colors.grey[100],
           }} 
           onClick={handleSave}>บันทึก
           </Button>
        </DialogActions>
      </Dialog>
      </>
  );
}