import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import { styled } from '@mui/material/styles';
import ImageCard from './imageCard';
import Checkbox from '@mui/material/Checkbox';
import Swal from 'sweetalert2'
import { MuiFileInput } from 'mui-file-input'
import axios from 'axios';
import { SaveNewsSub ,UpdateNewsSub,SaveImageSub,LoadImageNewsSub,DeleteNewsSubImg} from "../../context";
import RichTextEditor from 'react-rte';
import { Backdrop, CircularProgress } from '@mui/material';

export default function AddModalSub({id,dataSelect,open,colors,onClose,reloadCar}) {
    const [news, setNewsDetail] = React.useState({
      newssub_topic: '',      
      newssub_image: ''
    });
    const [file, setFile] = React.useState();
    const [image, setImage] = React.useState(null);
    const [loading,setLoad] = React.useState(false);
    const [editorState, setEditorState] = React.useState(
      () => RichTextEditor.createEmptyValue(),
    );
    const handleChangeImage = (newValue) => {
      setFile(newValue) 
      var filename = Date.now().toString() +'.jpg';     
      setNewsDetail({...news,newssub_image:filename})
    }
    const handleTextFieldChange = (event) => {
      setNewsDetail({
        ...news,
        [event.target.name]: event.target.value,
      });
    };
    const { newssub_topic} = news;
    const handleClose = () => {

      setNewsDetail({
        newssub_topic: '',
        newssub_image: ''})
        setEditorState( () => RichTextEditor.createEmptyValue())       
        setFile()    
        setImage(null)   
        onClose()
      };


    React.useEffect(()=>{

      if(id){
         setNewsDetail({
            newssub_topic: dataSelect.row.newssub_topic,
            newssub_image: dataSelect.row.newssub_image,
           
          })
         
          setEditorState(RichTextEditor.createValueFromString( dataSelect.row.newssub_detail, 'html'))
       
          setImage(dataSelect.row.newssub_image)
          
      }
      else{
        
      }
      
    },[open])
    const toolbarConfig = {
      display: [
        "INLINE_STYLE_BUTTONS",
        "BLOCK_TYPE_BUTTONS",
        "BLOCK_ALIGNMENT_BUTTONS",
        "LINK_BUTTONS",
        "BLOCK_TYPE_DROPDOWN",
        "HISTORY_BUTTONS",
      ],
      INLINE_STYLE_BUTTONS: [
        { label: "Bold", style: "BOLD", className: "custom-css-class" },
        { label: "Italic", style: "ITALIC" },
        { label: "Underline", style: "UNDERLINE" },
      ],
      BLOCK_TYPE_DROPDOWN: [
        { label: "Normal", style: "unstyled" },
        { label: "Inline Title", style: "header-three" },
      ],
      BLOCK_TYPE_BUTTONS: [
        { label: "UL", style: "unordered-list-item" },
        { label: "OL", style: "ordered-list-item" },
      ],
      BLOCK_ALIGNMENT_BUTTONS: [
        { label: "Align Left", style: "ALIGN_LEFT" },
        { label: "Align Center", style: "ALIGN_CENTER" },
        { label: "Align Right", style: "ALIGN_RIGHT" },
        { label: "Align Justify", style: "ALIGN_JUSTIFY" },
      ],
    };

    async function saveData(){
      setLoad(true)
      var formData = new FormData();   
        formData.append("id",id);       
        formData.append("newssub_detail",editorState.toString('html'))
        Object.keys(news).map((name)=>{     
      
            formData.append(name,news[name])      
          }      
        )     
        await axios.post(id ? UpdateNewsSub : SaveNewsSub,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }).then(async res=>{     
        
             uploadToServer(news['newssub_image'])
             setLoad(false)
          
        }).catch(error=>{
          console.log(error);
        });      
        
      }
    const handleSave = async () => {
      if(file == null){
        if(dataSelect == null){
          Swal.fire({
            title: 'ข้อมูลไม่ครบ ไม่สามารถบันทึก',           
            icon: 'error',
            showCancelButton: true,       
            confirmButtonText: 'ตกลง',
            
         })
        }
        else{
          saveData()
        }

        
      }
      else{
        saveData()
   
     }
    }
    const onChange = (value) => {
      setEditorState(value)
      // setNewsDetail({
      //   ...news,news_detail: value,
      // });
     
    };
     async function uploadToServer(filename) {
      
      if(file != null){
       
           
          const formData = new FormData();
          formData.append("file", file);
          formData.append("fileName", filename);
          await axios.post(SaveImageSub, formData, {
          headers: { "Content-Type": "multipart/form-data" },
          }).then(res => {})
      
    }
    
    handleClose()
   }
   

  const handleDeleteImage = (imageName)=>{
      
   
      var formData = new FormData();       
        formData.append("path",imageName);      
        axios.post(DeleteNewsSubImg,
        formData,{
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }).then(res=>{          
           
        }).catch(error=>{
        
        })
            
      
      setImage(null)
      setFile()

        reloadCar()
      
  }

  const removeImageData = ()=>{
      setImage(null)
      setFile()
  }
  return (
    <>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 10000 }}
        open={loading}
       // onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Dialog open={open} onClose={handleClose} >
        <DialogTitle>{id ? 'อัพเดทข้อมูลพาดหัวข่าว':'เพิ่มข้อมูลพาดหัวข่าว'}</DialogTitle>
        <DialogContent style={{display:'flex',placeContent:'center'}}>
        <Stack spacing={{ xs: 1, sm: 2 }}  direction="row" useFlexGap flexWrap="wrap" sx={{paddingTop:'10px',width:'480px'}}>
          
            <TextField
           
            autoFocus
            id="newssub_topic"
            name ="newssub_topic"
            value={newssub_topic}
            onChange={handleTextFieldChange}
            label="หัวข้อ"
            type="text"
            fullWidth
            variant="standard"
           // error
          />
          <Typography  style={{marginLeft:'0px',marginTop:'25px',display:'inline-flex'}} >รายละเอียด</Typography>
          <Box paddingTop={2}       width={'100%'}>
            <RichTextEditor
       
              // toolbarConfig={toolbarConfig} 
              editorClassName="rte-editor"
              toolbarClassName="rte-toolbar"
              value={editorState}
              onChange={onChange}
            />
</Box>
            
            <Typography   style={{marginLeft:'0px',marginTop:'10px',display:'inline-flex'}} >รูปประกอบข่าว<Typography marginLeft={2} color={'red'}> แนะนำขนาด 1080x600</Typography> </Typography>
            
            <Button   style={{marginTop:'10px',marginBottom:'10px'}} component="label" variant="contained" startIcon={<CloudUploadIcon />}>
                   อัพโหลดรูปภาพ
                   <MuiFileInput   inputProps={{ accept: '.png, .jpeg, .jpg' }} size='small' style={{width:250, marginLeft:10}} color={'info'} value={file} onChange={handleChangeImage} />
            </Button>
           <Box sx={{width:'480px'}}>

            
           <Stack  sx={{width:'420px'}}  direction="row" useFlexGap flexWrap="wrap">
             
              { file  ? 
                <Box>
                <Box textAlign={'-webkit-center'} onClick={()=> removeImageData()} sx={{cursor:'pointer'}} backgroundColor={'Brown'} borderRadius={20} m={1}>
                {file.name}
                <IconButton aria-label="delete" size="small">
                  <DeleteIcon fontSize="small" />
                  </IconButton>
                  </Box>
              
                  <ImageCard imagePath={URL.createObjectURL(file)} imageLarge={URL.createObjectURL(file)}/>
                </Box>
            

                :''}
                    
                {image?
                
               
                <Box>
                <Box textAlign={'-webkit-center'} onClick={()=> handleDeleteImage(image)}  sx={{cursor:'pointer'}}  backgroundColor={'Brown'} borderRadius={20} m={1}>{image}
                <IconButton aria-label="delete" size="small" >
                  <DeleteIcon fontSize="small" />
                  </IconButton>
                  </Box>
                <ImageCard imagePath={LoadImageNewsSub+image} imageLarge={LoadImageNewsSub+image}/>
                </Box>
                :''}

            </Stack>
           </Box> 


        </Stack>

       
        

       
        </DialogContent>
        <DialogActions>
          <Button sx={{
              backgroundColor: colors.blueAccent[700],
              color: colors.grey[100],
           }} 
            onClick={handleClose}>ยกเลิก
            </Button>
          <Button  sx={{
              backgroundColor: colors.blueAccent[700],
              color: colors.grey[100],
           }} 
           onClick={handleSave}>บันทึก
           </Button>
        </DialogActions>
      </Dialog>
      </>
  );
}