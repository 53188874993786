import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import { MuiFileInput } from 'mui-file-input'
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { styled } from '@mui/material/styles';
import ImageModel from './imageModal';
import axios from 'axios';
import { SelectBrandName,LoadLogo,DeleteLogo,DeleteBrandName } from "../../context";
import { DataGrid,GridToolbar } from '@mui/x-data-grid';
import Swal from 'sweetalert2'
import { IconButton } from '@mui/material';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
export default function AddModel({open,colors,onClose}) {
    const [brand, setBrand] = React.useState('');
    const [imageOpen, setImageOpen] = React.useState(false);
    const [imgPath,setImgPath] = React.useState(null)
    const [data, setData] = React.useState();
    const [id, setId] = React.useState(null);

    const handleClose = () => {
     setBrand('')
     onClose()
     };
     const handleEdit = (value)=>{
      setImgPath(value.row.brand_logo)
      setBrand(value.row.brand_name)
      setId(parseInt(value.row.id))
      setImageOpen(true)
      
     }
     const handleDelete = (value) => {    
      Swal.fire({
        title: 'คุณต้องการลบ '+value.row.brand_name +' หรือไม่?',           
        icon: 'warning',
        showCancelButton: true,       
        confirmButtonText: 'ตกลง',
        cancelButtonText: "ยกเลิก"
     }).then((result) => {
      if (result.isConfirmed) {
        var formData = new FormData();       
        formData.append("brandLogo", value.row.brand_logo);      
        axios.post(DeleteLogo,
        formData,{
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }).then(res=>{          
          deleteItem(value)
        }).catch(error=>{
       
        });
      } else if (result.isDenied) {
        
      }
    })
              
       
    }
    const deleteItem = (value)=>{   
    
    var formData = new FormData();       
    formData.append("id", parseInt(value.row.id));      
    axios.post(DeleteBrandName,
    formData,{
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }).then(res=>{      
      loadItem()
    }).catch(error=>{  
    }); 
       
    }
     const columns = [
        { field: "id", headerName: "ID" },
        {
          field: "brand_name",
          headerName: "ยี่ห้อ",    
          cellClassName: "name-column--cell",
          width: 300
        },      
        {
          field: "brand_logo",
          headerName: "โลโก้",
          width: 200,
          renderCell: (params) => 
         <>
             {<img width={'100%'} height={'100%'} style={{ objectFit:'contain'}} src={LoadLogo+params.value} /> }
          </> 
        },
        {
            field: "brand_create",
            headerName: "สร้างเมื่อ",    
            cellClassName: "name-column--cell",
            width: 150
        },  
        {
        
          headerName: "",    
          cellClassName: "name-column--cell",
          width: 150,
          renderCell: (params) => 
           <>
              
                <IconButton onClick={()=>handleEdit(params)}>
                <EditIcon />
              </IconButton>
              <IconButton onClick={()=>handleDelete(params)}>
                  <DeleteForeverIcon />
                 </IconButton>
           </> 
        
      },          
     
      ];

    const loadItem = ()=>{
      axios.get(SelectBrandName)
      .then(res=>{

        setData(res.data);

      }).catch(error=>{
        console.log(error);
      })
      }
       
    
    React.useEffect(()=>{
      if(!imageOpen){
        setBrand('')
        setImgPath(null)
        setId(null)
        loadItem()
      }
       

    },[imageOpen])
    const handleImage = (e)=>{
      if(brand == ''){
        Swal.fire({
            title: 'กรุณาใส่ชื่อยี่ห้อ',           
            icon: 'error',
        });
      }
      else{
        setImageOpen(true)
      }
      
       
    }
   
  return (
    <>
      <Dialog open={open} onClose={handleClose} 
      fullWidth
      maxWidth="md">
        <DialogTitle>เพิ่มยี่ห้อรถ</DialogTitle>
        <DialogContent >
            <Box textAlign={'right'}>        
                <TextField
            style={{width:'250px',marginLeft:'0px',marginTop:'10px'}}
            autoFocus
            value={brand}
            id="car_model"
            label="ยี่ห้อรถ"
            type="text"
            fullWidth
            variant="standard"
            onChange={(e)=>setBrand(e.target.value)}
           // error
          />
          <Button variant='contained' sx={{marginTop:'20px',marginLeft:'10px'}} onClick={(e)=> handleImage(e)}><Typography variant='h5'>+</Typography></Button>
        
          </Box>

        <Box
        m="0 0 0 0"
        height="60vh"
        
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-cellContent": {
            fontSize:'15px'
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[800],
            borderBottom: "none",
          },
          // "& .MuiDataGrid-columnHeaderTitle": {
          //     fontSize:'15px'
          // },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[800],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
          <DataGrid  rows={data} columns={columns} components={{ Toolbar: GridToolbar }}/>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button sx={{
              backgroundColor: colors.blueAccent[700],
              color: colors.grey[100],
           }} 
            onClick={handleClose}>ออก
            </Button>
         
        </DialogActions>
      </Dialog>
    <ImageModel open = {imageOpen} id = {id} brandName = {brand} imagePath = {imgPath} colors = {colors} onClose= {()=> setImageOpen(false)} />
    </>
  );
}