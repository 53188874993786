import { Box, Button, MenuItem, Typography,Select , useTheme,Stack, Paper, Grid, Container,TextField, Divider, Dialog, DialogContent, DialogActions } from "@mui/material";

import { tokens } from "../../theme";
import axios from 'axios';

import { useState,useEffect } from "react";

import { Slide } from 'react-slideshow-image';
import { Lightbox }  from "react-modal-image";
import FacebookIcon from '@mui/icons-material/Facebook';
import { FaLine } from "react-icons/fa6";

import { FaCarTunnel } from "react-icons/fa6";
import { PiArrowsInCardinalFill } from "react-icons/pi";
import { FaCirclePlay } from "react-icons/fa6";
import { SlSpeedometer } from "react-icons/sl";
import { PiEngineBold } from "react-icons/pi";
import { PiGasPumpBold } from "react-icons/pi";
import { GiGears } from "react-icons/gi";

import { NumericFormat } from 'react-number-format';

import { SelectCarById,LoadImageCar,LoadVideoCar, SaveMailBox } from "../../context";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
const CarDetail = ({car_id,modalOpen,modalClose}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [open,setOpen] = useState(false);
  const [videoClick,setVideo] = useState(false);
  const [exteriorClick,setExterior] = useState(true);
  const [interiorClick,setInterior] = useState(false);
  const [mon, setMon] = useState(12);
  const [carDetail,setCarDetail] = useState([]);
  const [focus,setFocus] = useState();
  const [carId,setCarId] = useState()
  const navigate = useNavigate()
  const handleClose = () => {
    
    modalClose()
    };
   
  const loadCarDetail = (car_id) =>{
    setCarId(car_id)
    var formData = new FormData();       
    formData.append("id", car_id);      
    axios.post(SelectCarById,
    formData,{
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }).then(res=>{     
    
      setCarDetail(res.data)
    }).catch(error=>{
      console.log(error)
    })
    }
  
  function currencyFormat(num) {
    return  num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
 }


  const closeLightbox = () => {
    setOpen(false);
  };
useEffect(()=>{ 
  if(localStorage.getItem('user')){
    if(modalOpen)loadCarDetail(car_id)

  }
else{
   navigate('/authen')
}
 

 
},[modalOpen])
  
   const divStyle = {

    objectFit:'cover',
    height: '200px', 
    backgroundPositionX:'center',
    width:'230px'
  }

  
  window.addEventListener('beforeunload', function (e) {
    //sessionStorage.clear();
  });
  const handleClear =()=>{
      setVideo(false)
      setExterior(false)
      setInterior(false)
      
  }
  
    return (
      <>
       <Dialog open={modalOpen} onClose={handleClose} 
      fullWidth
      maxWidth="lg">
     
    
  
      
      
      <Paper  style={{backgroundColor:colors.primary[400],padding:20}} >
     { 
       carDetail.map((item,index)=>(   
          item.id == car_id &&<>
          <Typography gutterBottom variant="h2" component="div" sx={{whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',textTransform:'uppercase'}}>{item.brand_name +' '+ item.car_model}</Typography>
          <Grid container>
              <Grid item xs={8}>
              {open && (
                    <Lightbox
                      hideDownload
                      large={LoadImageCar+focus}
                      onClose={closeLightbox}
                    />  )}
          {videoClick && (item.video_path? <Box display={'flex'} justifyContent={'center'}>  <video  style={{ ...divStyle}} autoPlay={true} controls >
                <source src={LoadVideoCar+item.video_path}  width="100%" type="video/mp4"/>
              </video></Box>
          :'')}
          {exteriorClick && 
            <Stack  display={'block'} direction={'column'}>
            {item.image_pathEx.split(',').map((slideImage, index)=> ( 
                <img onClick={()=> {setOpen(true);setFocus(slideImage)}} style={{...divStyle}} src={LoadImageCar+'Thumbnails_'+slideImage}>               
                </img> 
           
              ))} 
           </Stack>
          
          }
          {interiorClick && 
          <Stack  display={'block'} direction={'column'}>
            {item.image_pathIn.split(',').map((slideImage, index)=> ( 
                <img onClick={()=> {setOpen(true);setFocus(slideImage)}} style={{...divStyle}} src={LoadImageCar+'Thumbnails_'+slideImage}>               
                </img> 
           
              ))} 
           </Stack>
           }
          <Box sx={{display:'flex',justifyContent:'center',marginTop:'10px'}}>
          <Button variant=""  onClick={()=> {handleClear();setExterior(true)}}>
                    <FaCarTunnel fontSize={20} style={{marginRight:'10px'}}/> < Typography variant="logo"  fontSize={13}>Exterior ({item.image_pathEx.split(',').length})</Typography> 
         </Button>
         <Button variant=""  onClick={()=> {handleClear();setInterior(true)}}>
                    <PiArrowsInCardinalFill fontSize={20} style={{marginRight:'10px'}}/> < Typography variant="logo"  fontSize={13}>Interior ({item.image_pathIn.split(',').length})</Typography> 
         </Button>
         <Button variant=""  onClick={()=> {handleClear();setVideo(true)}}>
                    <FaCirclePlay fontSize={20} style={{marginRight:'10px'}}/> < Typography variant="logo"  fontSize={13}>video clip</Typography> 
         </Button>               
                
                
         </Box>
   
          </Grid>
              <Grid item xs={4}>
               
                <br/>
                <Box  style={{borderColor:colors.primary[300],borderRadius:'20px',borderStyle:'dashed',borderWidth:'1px',margin:'0 0 0 20px '}}>
                  <Stack direction='row' gap={2} m={2}>
                      < Typography variant="logo" fontSize={20}>Price</Typography> 
                      < Typography variant="logo" fontSize={30}color={colors.greenAccent[500]} >฿ {currencyFormat(parseInt(item.car_price))}</Typography> 
                  </Stack>
                </Box>     
                <br/>
                <Divider> < Typography variant="logo"  fontSize={16}>Car info</Typography>   </Divider>
        <Box display={"flex"} justifyContent={'space-around'} marginTop={2}>
        <Grid container xs={5}>
          <Grid item xs={2}>
           <SlSpeedometer  fontSize={25} />
          </Grid>
          <Grid item xs={10} paddingLeft={1.5}>
           
          < Typography variant="h5" fontWeight={'bold'}> {item.car_mile} KM</Typography> 
          < Typography variant="logo"  fontSize={16}>Mileage</Typography>  
          </Grid>
         </Grid>
       

         <Grid container  xs={5}>
          <Grid item xs={2}>
           <PiEngineBold  fontSize={25} />
          </Grid>
          <Grid item xs={8} paddingLeft={1.5}>
           
          < Typography variant="h5" fontWeight={'bold'}> {item.car_cc} CC</Typography> 
          < Typography variant="logo" fontSize={16}>Engine</Typography>  
          </Grid>
         </Grid>
       
       
         <Grid container  xs={5}>
          <Grid item xs={2}>
           <PiGasPumpBold  fontSize={25} />
          </Grid>
          <Grid item xs={8} paddingLeft={1.5}>
           
          < Typography variant="h5" textTransform={'uppercase'} fontWeight={'bold'}>{item.car_fuel}</Typography> 
          < Typography  variant="logo"  fontSize={16}>Fuel Type</Typography>  
          </Grid>
         </Grid>
       
         <Grid container xs={4}>
          <Grid item xs={2.5}>
           <GiGears  fontSize={25} />
          </Grid>
          <Grid item xs={8} paddingLeft={1.5}>
           
          < Typography variant="h5" fontWeight={'bold'}>{item.car_year}</Typography> 
          < Typography  variant="logo"  fontSize={16}>Year</Typography>  
          </Grid>
         </Grid>
       
         </Box>
         <br/>
         <Divider> < Typography variant="logo"  fontSize={16}>Overviews</Typography></Divider>
         < Typography variant="logo" fontSize={15}>   <div dangerouslySetInnerHTML={{ __html: item.car_detail }} /></Typography>
                <br/>         
                
              </Grid>
             
            </Grid>
          </>
       ))
     }

      </Paper>
     

      <br/>







    
  
        <DialogActions>
          <Button sx={{
              backgroundColor: colors.blueAccent[700],
              color: colors.grey[100],
           }} 
            onClick={handleClose}>ออก
            </Button>
         
        </DialogActions>
      </Dialog>
   
   
    </>
  );
};

export default CarDetail;
