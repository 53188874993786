import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import { MuiFileInput } from 'mui-file-input'
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { styled } from '@mui/material/styles';
import ImageCard from './imageCard';
import axios from 'axios';
import { SaveLogo,DeleteLogo ,SaveBrandName,LoadLogo,UpdateBrandName} from "../../context";

export default function ImageModel({open,colors,onClose,brandName,imagePath,id}) {

    const [name, setName] = React.useState(brandName);
    const [file, setFile] = React.useState(null);
    const [imgData, setImgData] = React.useState();
    const handleClose = () => {
      setImgData(null)
      setFile(null)
      onClose()
     };
     const handleSave = async () => {
      console.log(imagePath,file,name)
      var formData = new FormData();
      formData.append("name",name);
      formData.append("id",id);
      formData.append("brandLogo", file != null ? file.name : imagePath  ); 
     
     await axios.post(id ? UpdateBrandName : SaveBrandName,
      formData,{
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(res=>{       
        id ? deleteLogo() : uploadToServer()      
      }).catch(error=>{
        console.log(error);
      });      
      setImgData(null)
      setFile(null)
      onClose()
     };
     React.useEffect(()=>{
      setName(brandName)
     },[brandName])
  async function uploadToServer() {
   
      const formData = new FormData();
      formData.append("file", file);
      await axios.post(SaveLogo, formData, {
       headers: { "Content-Type": "multipart/form-data" },
      }).then(res => {})
    
   }
   async function deleteLogo() {
    if(file != null){ var formData = new FormData();       
      formData.append("brandLogo",imagePath);      
      axios.post(DeleteLogo,
      formData,{
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(res=>{          
        uploadToServer()   
      }).catch(error=>{
      
      });}
   
  }

    const handleChange = (newValue) => {
        setFile(newValue)
        const reader = new FileReader();
        reader.addEventListener("load", () => {
            setImgData(reader.result);
        });
        reader.readAsDataURL(newValue);
       
      }
  return (
    
      <Dialog open={open} onClose={handleClose} disableEscapeKeyDown   
      fullWidth
      maxWidth="sm">
        <DialogTitle>{id ? 'อัพเดทยี่ห้อรถ': 'เพิ่มยี่ห้อรถ'}</DialogTitle>
        <DialogContent >
     
        <Box textAlign={'center'} marginTop={2}>
            {file != null  ?  <img width={300} height={300} style={{marginBottom:5,objectFit:'contain'}}  src={imgData} /> :''}
            { imagePath != null && file == null  ?  <img width={300} height={300} style={{marginBottom:5,objectFit:'contain'}}  src={LoadLogo+imagePath} /> :''}
            {id?
             <TextField
             style={{width:'300px',marginBottom:'20px',marginTop:'10px'}}
             autoFocus
             value={name}
             id="car_model"
             label="ยี่ห้อรถ"
             type="text"
             fullWidth
             variant="standard"
             onChange={(e)=>setName(e.target.value)}
            // error
           />
           : <Typography variant='h3' marginBottom={2}>{name}</Typography>
          }
           
             <MuiFileInput color={'info'} label={'เลือกโลโก้'} value={file} onChange={handleChange} />
        </Box>
        
    
        </DialogContent>
        <DialogActions>
          <Button sx={{
              backgroundColor: colors.blueAccent[700],
              color: colors.grey[100],
           }} 
            onClick={handleClose}>ยกเลิก
            </Button>
          <Button  sx={{
              backgroundColor: colors.blueAccent[700],
              color: colors.grey[100],
           }} 
           onClick={handleSave}>{id ? 'อัพเดท': 'บันทึก'}
           </Button>
        </DialogActions>
      </Dialog>
   
  );
}