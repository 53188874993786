import { Box, Typography, useTheme,Button } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import { mockDataCar } from "../../data/mockData";
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import AddModal from "./addModal";
import AddModel from "./addModel";
import AddColor  from "./addColor";
import Header from "../../components/Header";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import  React,{useEffect, useState} from 'react';
import axios from 'axios';
import { SelectCar,DeleteCarImg,LoadImageCar } from "../../context";
import { IconButton } from '@mui/material';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Swal from 'sweetalert2'
import EditIcon from '@mui/icons-material/Edit';
import {  useNavigate } from "react-router-dom";
const CarManage = () => {
  const navigate = useNavigate()
  useEffect(()=>{    
    if(localStorage.getItem('user')){
      loadCar()
     
  }
  else{
     navigate('/authen')
  }
    
  },[])

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [carId, setCarId] = useState(null);
  const [dataSelect, setDataSelect] = useState(null);
  const handleEdit = (value)=>{
    setDataSelect(value)
    setCarId(parseInt(value.id))
    setOpen(true)
    
   }
   const handleDelete = (value) => {    
    Swal.fire({
      title: 'คุณต้องการลบ '+value.row.brand_name +' หรือไม่?',           
      icon: 'warning',
      showCancelButton: true,       
      confirmButtonText: 'ตกลง',
      cancelButtonText: "ยกเลิก"
   }).then((result) => {
    if (result.isConfirmed) {
      var formData = new FormData();       
      formData.append("brandLogo", value.row.brand_logo);      
      axios.post(DeleteCarImg,
      formData,{
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(res=>{          
       // deleteItem(value)
      }).catch(error=>{
     
      });
    } else if (result.isDenied) {
      
    }
  })
}
  const columns = [
   
    {
      field: "brand_name",
      headerName: "ยี่ห้อ",    
      cellClassName: "name-column--cell",
      width: 100
    },
    {
      field: "car_model",
      headerName: "รุ่น",
      headerAlign: "left",
      align: "left",
      width: 300
    },
    {
      field: "car_year",
      headerName: "ปีรถ",
      width: 100
    },
    {
      field: "color_name",
      headerName: "สีรถ",
      width: 100
    },
    {
      field: "car_price",
      headerName: "ราคา",
      width: 100
    },
    // {
    //   field: "car_detail",
    //   headerName: "รายละเอียดรถ",
    //   width: 200
    // },
    {
      field: "image_pathEx",
      headerName: "ภาพภายนอก",
      width: 200,
      renderCell:  (params) => {
        try{
        var img = params.row.image_pathEx.split(',')
    
        return  (
          <>
             {img != null ?
                img.map((value) => (             
                  <img width={100} src={LoadImageCar+'Thumbnails_'+value} /> 
                 )
                )
             :''}
          </> 
          )
         }catch(e){

          }
      }
      
    
    },    
    {
      field: "image_pathIn",
      headerName: "ภาพภายใน",
      width: 200,
      renderCell:  (params) => {
        try{
          var img = params.row.image_pathIn.split(',')
    
          return  (
            <>
               {img != null ?
                  img.map((value) => (             
                    <img width={100} src={LoadImageCar+'Thumbnails_'+value} /> 
                   )
                  )
               :''}
            </> 
            )
        }catch(e){

        }
        
      }
      
    
    },    
    {
      field: "car_sell",
      headerName: "สถานะ",
      width: 100,
      renderCell:  (params) => {
        var value = params.row.car_sell
        return  (
          <>
             {value == 1 ?
               'ยังไม่ขาย'
             :'ขายแล้ว'}
          </> 
          )
        
      }
      
    
    },    
    
    {
    
      headerName: "",    
      cellClassName: "name-column--cell",
      width:80,
      renderCell: (params) => 
       <>
          
            <IconButton disabled={JSON.parse(localStorage.getItem('user')).role == 3} onClick={()=>handleEdit(params)}>
            <EditIcon />
          </IconButton>
          {/* <IconButton onClick={()=>handleDelete(params)}>
              <DeleteForeverIcon />
             </IconButton> */}
       </> 
    
  },          
   
  ];
  const [open, setOpen] = useState(false);
  const [modelOpen, setModelOpen] = useState(false);
  const [colorOpen, setColorOpen] = useState(false);
  const [carList,setCarlist] = useState()
  const addClick = ()=>{
    setOpen(true)
  }
  const addModelClick = ()=>{
    setModelOpen(true)
  }
  const addColorClick = ()=>{
    setColorOpen(true)
  }

  const loadCar = () =>{
    
    axios.get(SelectCar)
    .then(res=>{
      
      setCarlist(res.data);

    }).catch(error=>{
      console.log(error);
    })
    }
     
  return (<>
    <Box m="20px">
       <Box display="flex" justifyContent="space-between" alignItems="center">
      <Header title="ข้อมูลรถ" subtitle="จัดการข้อมูลรถ ได้ที่นี้" />
      <Box sx={{pointerEvents:JSON.parse(localStorage.getItem('user')).role == 3 ?'none':'unset'}}> 
      <Button
            onClick={()=>addColorClick()}
            sx={{
              backgroundColor: colors.blueAccent[700],
              color: colors.grey[100],
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
              marginLeft:"10px"
            }}
          >   <AddCircleIcon sx={{ mr: "10px" }} />
            เพิ่มสีรถ
        </Button>

      <Button
            onClick={()=>addModelClick()}
            sx={{
              backgroundColor: colors.blueAccent[700],
              color: colors.grey[100],
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
              marginLeft:"10px"
            }}
          >   <AddCircleIcon sx={{ mr: "10px" }} />
            เพิ่มยี่ห้อ
        </Button>

        <Button
            onClick={()=>addClick()}
            sx={{
              backgroundColor: colors.blueAccent[500],
              color: colors.grey[100],
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px ",
              marginLeft:"10px"
            }}
          >   <AddCircleIcon sx={{ mr: "10px" }} />
            เพิ่มรถ
        </Button>
    
        </Box>
      </Box>
      <Box
        m="0 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-cellContent": {
            fontSize:'16px'
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[800],
            borderBottom: "none",
          },
          // "& .MuiDataGrid-columnHeaderTitle": {
          //     fontSize:'15px'
          // },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[800],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
      {carList ? <DataGrid  rows={carList} columns={columns} components={{ Toolbar: GridToolbar }}/>:''}
        
      </Box>
    </Box>
    <AddModel open = {modelOpen} colors = {colors} onClose= {()=> setModelOpen(false)}></AddModel>
    <AddColor open = {colorOpen} colors = {colors} onClose= {()=> setColorOpen(false)}></AddColor>
   <AddModal open = {open} colors = {colors} id = {carId}  reloadCar={()=>loadCar()} dataSelect={dataSelect} onClose= {()=> {setOpen(false);setCarId(null);loadCar();setDataSelect(null)}}/>
   </>
  );
};

export default CarManage;
