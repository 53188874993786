import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import { MuiFileInput } from 'mui-file-input'
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { styled } from '@mui/material/styles';

import axios from 'axios';
import { SaveColor,UpdateColor} from "../../context";

export default function ColorModal({open,colors,onClose,colorName,id}) {

    const [name, setName] = React.useState(colorName);

    const handleClose = () => {  
  
      onClose()
     };
     const handleSave = async () => {
    
      var formData = new FormData();
      formData.append("name",name);
      formData.append("id",id);
     
     await axios.post(id ? UpdateColor : SaveColor,
      formData,{
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(res=>{       
 
      }).catch(error=>{
      
      });      
     
      onClose()
     };
     React.useEffect(()=>{
      setName(colorName)
     },[colorName])

   
  return (
    
      <Dialog open={open} onClose={handleClose} disableEscapeKeyDown   
      fullWidth
      maxWidth="sm">
        <DialogTitle>{id ? 'อัพเดทสีรถ': 'เพิ่มสีรถ'}</DialogTitle>
        <DialogContent >
     
        <Box textAlign={'center'} marginTop={2}>
           
            {id?
             <TextField
             style={{width:'300px',marginBottom:'20px',marginTop:'10px'}}
             autoFocus
             value={name}            
             label="สีรถ"
             type="text"
             fullWidth
             variant="standard"
             onChange={(e)=>setName(e.target.value)}
            // error
           />
           : <Typography variant='h3' marginBottom={2}>{name}</Typography>
          }
           
        </Box>
        
    
        </DialogContent>
        <DialogActions>
          <Button sx={{
              backgroundColor: colors.blueAccent[700],
              color: colors.grey[100],
           }} 
            onClick={handleClose}>ยกเลิก
            </Button>
          <Button  sx={{
              backgroundColor: colors.blueAccent[700],
              color: colors.grey[100],
           }} 
           onClick={handleSave}>{id ? 'อัพเดท': 'บันทึก'}
           </Button>
        </DialogActions>
      </Dialog>
   
  );
}