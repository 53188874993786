import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import { MuiFileInput } from 'mui-file-input'
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { styled } from '@mui/material/styles';
import ColorModal from './colorModal';
import axios from 'axios';
import {SelectColor,DeleteColor } from "../../context";
import { DataGrid,GridToolbar } from '@mui/x-data-grid';
import Swal from 'sweetalert2'
import { IconButton } from '@mui/material';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
export default function AddColor({open,colors,onClose}) {
    const [color, setColor] = React.useState('');
    const [colorOpen, setColorOpen] = React.useState(false);
    const [data, setData] = React.useState();
    const [id, setId] = React.useState(null);

    const handleClose = () => {
     setColor('')
     onClose()
     };
     const handleEdit = (value)=>{    
      setColor(value.row.color_name)
      setId(parseInt(value.row.id))
      setColorOpen(true)
      
     }
     const handleDelete = (value) => {    
      Swal.fire({
        title: 'คุณต้องการลบ '+value.row.color_name +' หรือไม่?',           
        icon: 'warning',
        showCancelButton: true,       
        confirmButtonText: 'ตกลง',
        cancelButtonText: "ยกเลิก"
     }).then((result) => {
      if (result.isConfirmed) {
        var formData = new FormData();       
            formData.append("id", parseInt(value.row.id));      
            axios.post(DeleteColor,
            formData,{
            headers: {
                'Content-Type': 'multipart/form-data'
            }
            }).then(res=>{      
            loadItem()
            }).catch(error=>{  
            }); 
      } else if (result.isDenied) {
        
      }
    })
              
       
    }
  
     const columns = [
        { field: "id", headerName: "ID" },
        {
          field: "color_name",
          headerName: "ยี่ห้อ",    
          cellClassName: "name-column--cell",
          width: 300
        },             
        {
            field: "color_create",
            headerName: "สร้างเมื่อ",    
            cellClassName: "name-column--cell",
            width: 150
        },  
        {
        
          headerName: "",    
          cellClassName: "name-column--cell",
          width: 150,
          renderCell: (params) => 
           <>              
                <IconButton onClick={()=>handleEdit(params)}>
                <EditIcon />
              </IconButton>
              <IconButton onClick={()=>handleDelete(params)}>
                  <DeleteForeverIcon />
                 </IconButton>
           </> 
        
      },          
     
      ];

    const loadItem = ()=>{
      axios.get(SelectColor)
      .then(res=>{

        setData(res.data);

      }).catch(error=>{
        console.log(error);
      })
      }
       
    
    React.useEffect(()=>{
      if(!colorOpen){
        setColor('')
        setId(null)
        loadItem()
      }
       

    },[colorOpen])

    const handleColor = (e)=>{
        if(color == ''){
          Swal.fire({
              title: 'กรุณาใส่ชื่อสี',           
              icon: 'error',
          });
        }
        else{
          setColorOpen(true)
        }
        
         
      }
   
  return (
    <>
      <Dialog open={open} onClose={handleClose} 
      fullWidth
      maxWidth="md">
        <DialogTitle>เพิ่มสีรถ</DialogTitle>
        <DialogContent >
            <Box textAlign={'right'}>        
                <TextField
            style={{width:'250px',marginLeft:'0px',marginTop:'10px'}}
            autoFocus
            value={color}
            label="สีรถ"
            type="text"
            fullWidth
            variant="standard"
            onChange={(e)=>setColor(e.target.value)}
           // error
          />
          <Button variant='contained' sx={{marginTop:'20px',marginLeft:'10px'}} onClick={(e)=> handleColor(e)}><Typography variant='h5'>+</Typography></Button>
        
          </Box>

        <Box
        m="0 0 0 0"
        height="60vh"
        
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-cellContent": {
            fontSize:'15px'
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[800],
            borderBottom: "none",
          },
          // "& .MuiDataGrid-columnHeaderTitle": {
          //     fontSize:'15px'
          // },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[800],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
          <DataGrid  rows={data} columns={columns} components={{ Toolbar: GridToolbar }}/>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button sx={{
              backgroundColor: colors.blueAccent[700],
              color: colors.grey[100],
           }} 
            onClick={handleClose}>ออก
            </Button>
         
        </DialogActions>
      </Dialog>
    <ColorModal open = {colorOpen} id = {id} colorName = {color} colors = {colors} onClose= {()=> setColorOpen(false)} />
    </>
  );
}